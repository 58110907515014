import { BrowserModule } from '@angular/platform-browser';
import { DoBootstrap, ErrorHandler, Injector, NgModule } from '@angular/core';

import {
  getOrderformState,
  OrderformModuleNgrx,
  WidgetsModule,
  WrapperComponent,
  MonitoringService,
  Wrapper2Component,
  getOrderformSubmitState,
  getOrderformConfig,
} from '@orderform/widgets';

import { Store, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { createCustomElement } from '@angular/elements';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeSl from '@angular/common/locales/sl';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WaitingService } from '../../../../libs/orderform-widgets/src/lib/services/waiting.service';
import {
  debounce,
  filter,
  map,
  skipWhile,
  withLatestFrom,
} from 'rxjs/operators';
import { of, timer } from 'rxjs';
import { environment } from '../environments/environment';
import {
  InterfaceToastItemDefaultConfig,
  ToasterConfigToken,
  ToasterModule,
  ToasterService,
} from '@digistore/ds24-ui/toaster';
import { OrderformIframeService } from '../../../../libs/orderform-widgets/src/lib/services/orderform-iframe.service';
import { ElevioComponent, ElevioModule } from '@ds24-ui-components/elevio';
import { ApiService } from 'libs/orderform-widgets/src/lib/services/api.service';
import { ApmErrorHandler, ApmModule } from '@elastic/apm-rum-angular';

registerLocaleData(localeDe);
registerLocaleData(localeEs);
registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localeNl);
registerLocaleData(localePl);
registerLocaleData(localePt);
registerLocaleData(localeSl);

let orderformIframeServiceRef: OrderformIframeService;

@NgModule({
  declarations: [],
  imports: [
    ApmModule,
    // RouterModule.forRoot([], { relativeLinkResolution: 'legacy' }),
    WidgetsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule, // need to include there because we have an error on radio inputs
    BrowserModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot(),
    OrderformModuleNgrx,
    ToasterModule,
    ElevioModule.forRoot(),
    /* istanbul ignore next */
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          name: 'Orderform',
        }),
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '',
    },
    {
      provide: ToasterConfigToken,
      // Do not use factory because we have circular deps in iframeService...
      useValue: <InterfaceToastItemDefaultConfig>{
        close: true,
        duration: 100000,
        executeFunctionOnToast: (toastData) => {
          if (orderformIframeServiceRef) {
            orderformIframeServiceRef.scrollToIframeElementPosition(0);
          }
        },
      },
    },
    { provide: ErrorHandler, useClass: ApmErrorHandler },
  ],
  bootstrap: [],
})
export class AppModule implements DoBootstrap {
  constructor(
    private _injector: Injector,
    private _apiService: ApiService,
    private _toasterService: ToasterService,
    private _waitingService: WaitingService,
    private _orderformIframeService: OrderformIframeService,
    private _store: Store<any>,
    _monitoring: MonitoringService
  ) {
    // API funktionen veröffentlichen
    (window as any).dsOrderform = this._apiService;
    (window as any).dsToast = this._toasterService; // for testing

    // prevent public access via window
    orderformIframeServiceRef = this._orderformIframeService;
    (window as any).orderformIframeServiceRef = orderformIframeServiceRef;
    (window as any).wait = this._waitingService;
    this._store
      .select(getOrderformSubmitState)
      .pipe(
        skipWhile((submitState) => submitState === 'initial'),
        withLatestFrom(
          this._store
            .select(getOrderformConfig)
            .pipe(map((config) => config.hide_process_popup))
        ),
        debounce(([submitState]) =>
          submitState === 'pending' ? timer(300) : of(null)
        )
      )
      .subscribe(([submitState, hide]) => {
        switch (submitState) {
          case 'pending':
            this._waitingService.pending(hide);
            break;
          case 'success':
            this._waitingService.ready(hide);
            break;
          default:
            this._waitingService.hide();
        }
      });

    this._orderformIframeService.init();

    _monitoring.initialize();
  }

  ngDoBootstrap() {
    const wrapperComponent = createCustomElement(WrapperComponent, {
      injector: this._injector,
    });
    customElements.define('ds-orderform-wrapper', wrapperComponent);

    // register element to init elevio via web-component
    const elevioInitComp = createCustomElement(ElevioComponent, {
      injector: this._injector,
    });
    customElements.define('ds-elevio-start', elevioInitComp);

    const wrapper2Component = createCustomElement(Wrapper2Component, {
      injector: this._injector,
    });
    customElements.define(
      'ds-orderform-payment-plan-changes',
      wrapper2Component
    );

    /**
     * Wird im PGB-Editor benötigt und nur im Übersetzungseditor verwenden.
     * Um Widgets im Übersetzungstext anzeigen zu können (tinymce iframe + Hauptframe).
     */
    if (!(window as any).__dsOrderform_disable_footer) {
      const footerHTML =
        '<ds-orderform-wrapper component="Footer"></ds-orderform-wrapper>' +
        '<ds-orderform-footer></ds-orderform-footer>';

      document
        .getElementsByTagName('body')[0]
        .insertAdjacentHTML('beforeend', footerHTML);

      const notificationsComp = `
<ds-orderform-wrapper component="Notifications" visible-force="1"></ds-orderform-wrapper>
<ds-orderform-notifications></ds-orderform-notifications>`;
      document
        .getElementsByTagName('body')[0]
        .insertAdjacentHTML('beforeend', notificationsComp);
    }
  }
}
