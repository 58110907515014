import {
  Component,
  ComponentRef,
  ElementRef,
  NgZone,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  isElementInViewport,
  viewportVisibilityChange,
} from '@digistore/ds24-ui/rxjs';
import { debounceTime, filter, take } from 'rxjs/operators';
import { OrderformIframeService } from '../../services/orderform-iframe.service';

@Component({
  selector: 'orderform-wrapper2',
  templateUrl: './wrapper2.component.html',
})
export class Wrapper2Component {
  static loadingShift = 0;

  @ViewChild('container', { read: ViewContainerRef })
  private _container: ViewContainerRef;

  constructor(
    private _element: ElementRef,
    private _ngZone: NgZone,
    private _orderformIframe: OrderformIframeService
  ) {}

  ngAfterViewInit(): void {
    const el = this._element.nativeElement as HTMLElement;
    const tag = el.tagName.toLowerCase();

    this._ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        if (
          isElementInViewport(this._element.nativeElement, 300) ||
          this._orderformIframe.iframeActive
        ) {
          this._ngZone.run(() => this._loadComponent(tag));
          return;
        }
        viewportVisibilityChange(this._element.nativeElement, 300)
          .pipe(
            debounceTime(10),
            filter((visible) => visible),
            take(1)
          )
          .subscribe(() => {
            this._ngZone.run(() => this._loadComponent(tag));
          });
      }, Wrapper2Component.loadingShift++ * 50);
    });
  }

  private async _loadComponent(tag) {
    let componentRef: ComponentRef<any>;
    let importComponent;
    switch (tag) {
      case 'ds-orderform-payment-plan-changes':
        importComponent = await import(
          '../../widgets/payment-plan-changes/payment-plan-changes.component'
        );
        this._container.clear();
        componentRef = this._container.createComponent(
          importComponent.PaymentPlanChangesComponent
        );
        break;
      /* istanbul ignore next: not testable */
      default:
        throw new Error('custom element ' + tag + ' has no mapping to load');
    }

    const el = this._element.nativeElement as HTMLElement;

    for (let i = 0; i < el.attributes.length; i++) {
      const attr = el.attributes[i];
      if (['ng-version'].indexOf(attr.name) >= 0) {
        continue;
      }
      componentRef.setInput(attr.name, attr.value);
    }
  }
}
